/* Menu trigger button */
.nav-menu-trigger {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  color: #fff;
  opacity: 0.85;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 18px;
}

.nav-menu-trigger:hover {
  opacity: 1;
  background: rgba(255, 255, 255, 0.1);
}

.nav-menu-trigger:focus-visible {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
}

/* Menu content */
.nav-menu-content {
  min-width: 240px;
  background-color: #1c2333;
  border-radius: 6px;
  padding: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  border: 1px solid #30363d;
  animation: slideIn 0.1s ease;
}

/* Menu items */
.nav-menu-item {
  all: unset;
  font-size: 14px;
  line-height: 1;
  color: #D1D5DA;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  position: relative;
  user-select: none;
  cursor: pointer;
  transition: all 0.2s ease;
}

.nav-menu-item:hover {
  background-color: #2d3343;
}

.nav-menu-item:focus-visible {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
}

/* Menu links */
.nav-menu-link {
  all: unset;
  flex: 1;
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* Icons in menu items */
.nav-menu-icon {
  display: inline-flex;
  margin-right: 12px;
  color: #8B949E;
}

/* Separators */
.nav-menu-separator {
  height: 1px;
  background-color: #30363d;
  margin: 4px;
}

/* Submenu trigger */
.nav-menu-sub-trigger {
  padding-right: 32px;
}

.nav-menu-sub-trigger::after {
  content: '→';
  position: absolute;
  right: 12px;
  color: #8B949E;
  transition: transform 0.2s ease;
}

.nav-menu-sub-trigger[data-state='open']::after {
  transform: rotate(90deg);
}

/* Submenu content */
.nav-menu-sub-content {
  min-width: 220px;
  background-color: #1c2333;
  border-radius: 6px;
  padding: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  border: 1px solid #30363d;
  animation: slideIn 0.1s ease;
}

/* Menu arrow */
.nav-menu-arrow {
  fill: #30363d;
}

/* Animation */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
} 