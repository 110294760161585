/* Import Annie Use Your Telescope font */
/* @import url('https://fonts.googleapis.com/css2?family=Annie+Use+Your+Telescope&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Love+Light&display=swap');

.app-header {
  background: linear-gradient(to right, #1a1a1a 0%, #2d2d2d 100%);
  display: flex;
  align-items: center;
  padding: 0 24px;
  height: 48px !important;
  line-height: 48px;
  justify-content: space-between;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1000;
}

/* Left section with menu and logo */
.header-left {
  display: flex;
  align-items: center;
  gap: 20px;
}

/* Menu icon styling */
.menu-icon {
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: all 0.2s ease;
  opacity: 0.85;
}

.menu-icon:hover {
  opacity: 1;
  background: rgba(255, 255, 255, 0.1);
}

.menu-icon.open {
  background: rgba(255, 255, 255, 0.15);
}

/* Logo styling */
.logo-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.logo-image {
  height: 28px;
  width: auto;
  opacity: 0.95;
  transition: opacity 0.2s ease;
}

.logo-text {
  color: #fff;
  font-size: 32px;
  font-family: 'Love Light', cursive;
  letter-spacing: 1px;
  line-height: 48px;
  transform: translateY(-1px);
}

/* Right section with avatar */
.header-right {
  min-width: 200px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.user-avatar {
  cursor: pointer;
}

.avatar-icon {
  background: rgba(255, 255, 255, 0.15);
  border: none;
  transition: all 0.2s ease;
  opacity: 0.85;
}

.avatar-icon:hover {
  opacity: 1;
  background: rgba(255, 255, 255, 0.25);
}

/* Menu styling - always light mode for ALL menus and submenus */
.ant-dropdown .ant-dropdown-menu,
.ant-dropdown-menu-submenu .ant-dropdown-menu {
  background: #fff !important;
}

.ant-dropdown .ant-dropdown-menu-item,
.ant-dropdown .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu .ant-dropdown-menu-item,
.ant-dropdown-menu-submenu .ant-dropdown-menu-submenu-title {
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-dropdown .ant-dropdown-menu-item .anticon,
.ant-dropdown-menu-submenu .ant-dropdown-menu-item .anticon {
  color: rgba(0, 0, 0, 0.65) !important;
}

.ant-dropdown .ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu .ant-dropdown-menu-item-divider {
  background-color: rgba(0, 0, 0, 0.06) !important;
}

/* Force light mode for submenu popup */
.ant-dropdown-menu-submenu-popup {
  background: #fff !important;
}

.ant-dropdown-menu-submenu-popup .ant-dropdown-menu {
  background: #fff !important;
}

.ant-dropdown-menu-submenu-popup .ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-popup .ant-dropdown-menu-submenu-title {
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-dropdown-menu-submenu-popup .ant-dropdown-menu-item .anticon {
  color: rgba(0, 0, 0, 0.65) !important;
}

.ant-dropdown-menu-submenu-popup .ant-dropdown-menu-item-divider {
  background-color: rgba(0, 0, 0, 0.06) !important;
}
