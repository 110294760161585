.residual-check-container {
  padding: 20px;
}

h2 {
  margin-bottom: 16px;
}

.filter-container {
  margin-bottom: 16px;
}

.ant-table-wrapper {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.ant-table-thead > tr > th {
  background-color: #f0f2f5;
  white-space: nowrap;
}

.ant-table-tbody > tr > td {
  white-space: nowrap;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.ant-tag {
  margin: 2px;
}

.ant-tooltip {
  max-width: 300px;
}

